"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dataTestId = void 0;
exports.dataTestId = {
    configurator: {
        layout: {
            headerContainer: 'configurator-layout-header',
            exitBtn: 'configurator-layout-exitBtn',
            exitBtnLabel: 'configurator-layout-exitBtn-label',
            logoBtn: 'configurator-layout-logoBtn',
            marxentAppContainer: 'configurator-layout-marxentApp-Container',
            footer: 'configurator-layout-footer',
        },
    },
    cdt: {
        layout: {
            btnBookAnAppointment: 'cdt-layout-btnBookAnAppointment',
            btnCreateNewDesign: 'cdt-layout-btnCreateNewDesign',
            btnDuplicate: 'cdt-layout-btnDuplicate',
            btnExit: 'cdt-layout-btnExit',
            btnShare: 'cdt-layout-btnShare',
            header: 'cdt-layout-header',
            iframeBom: 'cdt-layout-iframeBom',
            iframePlanner: 'cdt-layout-iframePlanner',
            iframeMarxentContainer: 'cdt-layout-iframeMarxentContainer',
        },
        duplicateDesign: {
            btnCancel: 'cdt-duplicateDesign-btnCancel',
            btnSubmit: 'cdt-duplicateDesign-btnSubmit',
        },
        shareDesign: {
            btnAccessType: 'cdt-shareDesign-btnAccessType',
            btnCopy: 'cdt-shareDesign-btnCopy',
            btnSendViaEmail: 'cdt-shareDesign-btnSendViaEmail',
            btnSendViaFacebook: 'cdt-shareDesign-btnSendViaFacebook',
            btnSendViaTwitter: 'cdt-shareDesign-btnSendViaTwitter',
            btnSendViaWhatsApp: 'cdt-shareDesign-btnSendViaWhatsApp',
            link: 'cdt-shareDesign-link',
            optionEdit: 'cdt-shareDesign-optionEdit',
            optionView: 'cdt-shareDesign-optionView',
        },
    },
    component: {
        accordionHip: {
            toggler: 'accordionHip-toggler',
        },
        hammertime: {
            icon: 'hammertime-icon',
        },
        modal: {
            btnClose: 'modal-btnClose',
            container: 'modal-container',
            header: 'modal-header',
            overlay: 'modal-overlay',
        },
        notification: {
            btnClose: 'notification-btnClose',
            container: 'notification-container',
        },
        productList: {
            advisoryWarningContainer: 'productList-advisoryWarningContainer',
            btnAddToBasket: 'productList-btnAddToBasket',
            btnClose: 'productList-btnClose',
            btnCopyQuote: 'productList-btnCopyQuote',
            btnCreateNewQuote: 'productList-btnCreateNewQuote',
            btnDownload: 'productList-btnDownload',
            btnNavigate: 'productList-btnNavigate',
            btnExportBOMToXML: 'productList-btnExportBOMToXML',
            container: 'productList-container',
            flagCopyQuote: 'productList-flagCopyQuote',
            groupName: 'productList-groupName',
            groupProductList: 'productList-groupProductList',
            header: 'productList-header',
            linkFinanceOptions: 'productList-linkFinanceOptions',
            listContainer: 'productList-listContainer',
            orderSummaryContainer: 'productList-orderSummaryContainer',
            orderSummaryPrice: 'productList-orderSummaryPrice',
            placeholderNoData: 'productList-placeholderNoData',
            productItem: 'productList-productItem',
            productItemCode: 'productList-productItemCode',
            productItemName: 'productList-productItemName',
            productQuantity: 'productList-productItemQuantity',
            productItemPrice: 'productList-productItemPrice',
            productItemUnitPrice: 'productList-productItemUnitPrice',
            productPrice: 'productList-productPrice',
            productTitle: 'productList-productTitle',
            quoteErrorContainer: 'productList-quoteErrorContainer',
            quoteInfoContainer: 'productList-quoteInfoContainer',
            radioInstallation: 'productList-radioInstallation',
            regulatoryWarningContainer: 'productList-regulatoryWarningContainer',
            title: 'productList-title',
            titleQuoteInfo: 'productList-titleQuoteInfo',
            missingProductMsg: 'missing-product-msg',
            productStockMessages: 'productList-productStockMessages',
        },
    },
    ab: {
        commonElements: {
            toastMessage: 'toast',
            loadingSpinner: 'loading',
        },
        storeDetails: {
            typeAheadInput: 'type-ahead-input',
            storeList: 'accordion',
            storeCard: {
                storeCardHeader: 'store-card-header',
                storeName: 'store-name',
                distanceFromUser: 'distance-from-user',
                openingTimes: 'opening-times',
                openingTimesTitle: 'opening-times-title',
                facilitiesLinkText: 'facilities-link-text',
                selectStoreButton: 'select-store-button',
            },
            suggestionList: 'input-options',
            locationSuggestion: 'menu-item',
            mapWidget: 'map',
        },
        calendar: {
            calendarWidget: 'calendar',
            dayCell: 'day-cell',
            dayCellToday: 'day-cell-today',
            previousMonthArrow: 'nav-previous-month',
            nextMonthArrow: 'nav-next-month',
            timeSlotsBoard: 'time-slots-board',
            timeSlot: 'time-slot',
            monthName: 'month-name',
            warningMessage: 'alert',
        },
        header: {
            headerArea: 'header-area',
            headerTitle: 'header-title',
            headerSubtitle: 'header-subtitle',
            stepProgressBar: 'step-progress-bar',
            stepProgressWidget: 'step-progress-widget',
        },
        footer: {
            footerElement: 'footer',
            continueButton: 'continue-button',
            backButton: 'back-button',
        },
        personalDetails: {
            firstNameInput: 'first-name-input',
            lastNameInput: 'last-name-input',
            emailAddressInput: 'email-address-input',
            phone: {
                phoneField: 'phone-field',
                countryCode: 'country-code',
                phoneNumberInput: 'phone-number-input',
                countryCodesDropDown: 'country-codes-drop-down',
            },
            policyBlock: 'policy-block',
            questions: 'questions-select',
        },
        bookingDetails: {
            bookingDetailsBar: 'booking-details-bar',
            selectedDate: 'selected-date',
            selectedTime: 'selected-time',
            selectedAppointmentType: 'selected-appointment-type',
            selectedStore: 'selected-store',
        },
        confirmationDetails: {
            confirmationHeader: 'confirmation-header',
            printingButton: 'printing-button',
            confirmationTableDesktop: 'confirmation-table-desktop',
            confirmationTableMobile: 'confirmation-table-mobile',
            appointmentType: 'appointment-type',
            selectedStore: 'selected-store',
            selectedStoreLink: 'selected-store-link',
            mobile: {
                appointmentDate: 'appointment-date',
                appointmentTime: 'appointment-time',
                appointmentDuration: 'appointment-duration',
                customerFirstLasName: 'customer-first-last-name',
                customerEmail: 'customer-email',
                customerPhoneNumber: 'customer-phone-number',
                customerPersonalData: 'customer-personal-data',
            },
        },
        appointmentAmendments: {
            cancellationPopUp: 'cancel-appointment-pop-up',
            confirmCancelButton: 'confirm-cancel-appointment-btn',
            abortCancelLink: 'abort-cancel-link',
        },
    },
    testWrapper: {
        genTokenBtn: 'gen-token-btn',
        projectName: 'project-name',
    },
};
